/*
 * @Description: 自动更新
 */

let currentVersion; // 当前版本
let version; // 新版本
// const timeData = 60 * 1000 // 检查间隔时间
const timeData = 20 * 1000; // 检查间隔时间
let hidden = false; // 页面是否隐藏
let setTimeoutId;
let needTip = true; // 默认开启提示

// 获取版本号
const getVersion = async () => {
  return fetch("/version.json?timestep=" + Date.now()).then((res) =>
    res.json(),
  );
};

// 检查更新
const checkUpdate = async () => {
  console.log("***************checkUpdate**************");
  const currentVersion = sessionStorage.getItem("version");
  version = (await getVersion()).version;
  // 本地没有 version，表示刚进入系统，直接塞值
  if (!currentVersion) return sessionStorage.setItem("version", version);
  let needRefresh = false;
  if (Number(version) !== Number(currentVersion)) {
    console.log("%c 发现新版本~~~~~~", "color: red");
    needRefresh = true;
  }
  return needRefresh;
};

// 自动更新
const autoUpdate = async () => {
  setTimeoutId = setTimeout(async () => {
    // 页面隐藏了就不检查更新
    if (!hidden) {
      const willUpdate = await checkUpdate();
      console.log(
        "🚀 ~ file: auto-update.js:71 ~ setTimeoutId=setTimeout ~ willUpdate, version:",
        willUpdate,
        version,
      );

      if (willUpdate && needTip) {
        // 延时更新，防止部署未完成用户就刷新空白
        setTimeout(() => {
          //*****右下角通知提示 */
          window.dispatchEvent(
            new CustomEvent("onmessageUpdate", {
              detail: {
                msg: "检测到有内容更新，为保证您的体验，请刷新网页后继续使用。",
                version: version,
              },
            }),
          );
          //******************* */
        }, 10000);
        needTip = false; // 关闭更新提示，防止重复提醒
      }
    }
    if (needTip) {
      console.warn("needTip autoUpdate");
      autoUpdate();
    }
  }, timeData);
};

// 停止检测更新
const stop = () => {
  if (setTimeoutId) {
    clearTimeout(setTimeoutId);
    setTimeoutId = "";
  }
};

// 开始检查更新
const start = async () => {
  // currentVersion = (await getVersion()).version
  autoUpdate();
  console.log(
    "🚀 ~ file: auto-update.js:97 ~ start ~ currentVersion:",
    currentVersion,
  );
  // 监听页面是否隐藏
  document.addEventListener("visibilitychange", () => {
    hidden = document.hidden;
    console.log(
      "🚀 ~ file: auto-update.js:64 ~ document.addEventListener ~ hidden, needTip:",
      hidden,
      needTip,
    );
    // 页面隐藏了就不检查更新。或者已经有一个提示框了，防止重复提示。
    if (!hidden && needTip) {
      autoUpdate();
    } else {
      stop();
    }
  });
};

export default { start };
