<template>
  <PageNav
    class="pageNav"
    :opacityBg="opacityBg"
    :class="{ 'pageNav-show': showNavBar, 'es-pageNav--opacity': opacityBg }"
    @onLogin="
      onLogin();
      openLoginAnimate = true;
    "
  />
  <el-scrollbar ref="scrollbarRef" class="es-body" @scroll="onScroll">
    <div
      class="es-pageContent animate__animated animate__fadeIn"
      :style="{
        'background-color': getBg,
        'padding-top': route.path === '/home' ? '0' : '56px',
      }"
    >
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component
            :is="Component"
            :key="$route.name"
            v-if="$route.meta.keepAlive"
          />
        </keep-alive>
        <component
          :is="Component"
          :key="$route.name"
          v-if="!$route.meta.keepAlive"
        />
      </router-view>
    </div>
    <PageBottom />
    <el-backtop
      title="回到顶部"
      :right="44"
      :bottom="330"
      :visibility-height="0"
      v-if="windowScrollStore().$state.scrollTop > 200"
    >
      <div
        style="
          height: 100%;
          width: 100%;
          text-align: center;
          line-height: 40px;
          color: #1989fa;
          border-radius: 50%;
          background-color: var(--el-bg-color-overlay);
          box-shadow: var(--el-box-shadow-lighter);
          display: flex;
          align-items: center;
          justify-content: center;
        "
        @click="windowScroll.SET_SCROLL_TOP(0)"
      >
        <img :src="backTop" width="32px" height="32px" />
      </div>
    </el-backtop>
  </el-scrollbar>
  <OpenVip
    :title="useUserStore().$state.openVipTitle"
    :visible="useUserStore().$state.openVipVisible"
    :submitText="useUserStore().$state.openVipSubmitTitle"
  />
  <Login
    class="animate__animated"
    v-if="openLoginAnimate"
    :openLogin="useUserStore().$state.openLoginVisible"
    @onCancel="useUserStore().openLogin(false)"
    :class="
      useUserStore().$state.openLoginVisible
        ? 'animate__fadeIn'
        : 'animate__fadeOut'
    "
  />
  <ConsultationNav />
  <!-- 会员支付弹窗 -->
  <MembersBuy v-if="showMembersBuy" />
  <NewUserVip
    :visible="useUserStore().$state.showNewUserVip"
    @onHandleClose="useUserStore().$state.showNewUserVip = false"
  />
  <CnNotify :updateInfo="updateInfo" @onHandleClose="updateInfo.type = false" />
</template>
<script lang="ts" setup>
import { onMounted, ref, Ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { windowScrollStore } from "@/store/modules/windowScroll";
import { useUserStore } from "@/store/modules/user";
import { getToken } from "@/utils/auth";
import { trackApi, saveByCodeApi } from "@/api/user";
import { generateUUID } from "@/utils/richText";
import backTop from "@/assets/svg/back_top.svg";
const opacityBg: Ref<boolean> = ref(true); // 是否展示透明背景
const route = useRoute();
const router = useRouter();
const showNavBar: Ref<boolean> = ref(true);
const openLoginAnimate: Ref<boolean> = ref(false); // 登录动画执行完毕弹窗
const showMembersBuy: Ref<boolean> = ref(false); //订阅会员弹框状态
const updateInfo: Ref<any> = ref({
  type: false,
  version: "",
  msg: "",
}); // 弹窗更新
// 埋点方法
window.trackFunction = (eventId: string) => {
  let _uuid = "";
  if (!localStorage.getItem("uuid")) {
    _uuid = generateUUID();
    localStorage.setItem("uuid", _uuid);
  } else {
    _uuid = localStorage.getItem("uuid");
  }
  const _data = {
    eventId,
    recordId: _uuid,
  };
  getToken() &&
    Object.assign(_data, { userId: useUserStore().$state.userInfo.id });
  trackApi(_data);
};

onMounted(() => {
  watchUpdate();
  useUserStore().token === "" &&
    getToken() &&
    (useUserStore().$state.token = getToken());
});
const getLinkCodeRecord = async () => {
  const data = route.query.liveCode;
  await saveByCodeApi(data);
};
// 监听路由改变
const windowScroll = windowScrollStore();
watch(
  () => route.path,
  () => {
    opacityBg.value = false;
    windowScroll.SET_SCROLL_TOP(0);
  },
  { immediate: true },
);
// 统计点击链接进入项目的次数
watch(
  () => route,
  (val) => {
    const { query, path } = val;
    if (query.liveCode) {
      getLinkCodeRecord()
        .then(() => {
          const newQuery = { ...query };
          delete newQuery.liveCode;
          router.replace({
            path,
            query: newQuery,
          });
        })
        .catch((error) => {
          console.error("获取链接码记录失败:", error);
        });
    }
    var _hmt = _hmt || [];
    (function () {
      const { VITE_ENV } = import.meta.env;
      var hm = document.createElement("script");
      if (VITE_ENV === "prod") {
        hm.src = "https://hm.baidu.com/hm.js?d635ba8e4795c232b0516191708d5972"; // production
      } else if (VITE_ENV === "test") {
        hm.src = "https://hm.baidu.com/hm.js?1334de19664027739970f62f9bac088b"; // test
      } else {
        return;
      }
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  },
  { immediate: true, deep: true },
);
const scrollbarRef = ref(null);
// 控制页面滚动距离
watch(windowScroll, (e) => {
  scrollbarRef.value!.setScrollTop(e.scrollTop);
});
watch(useUserStore().$state, (val: any) => {
  // 打开登录弹窗
  if (val.openLoginVisible) {
    openLoginAnimate.value = true;
  } else {
    setTimeout(() => {
      openLoginAnimate.value = false;
    }, 500);
  }
  if (val.showMembersBuy) {
    showMembersBuy.value = true;
  } else {
    setTimeout(() => {
      showMembersBuy.value = false;
    }, 500);
  }

  val.publicKey !== "" &&
    useUserStore().$state.fileUrl === "" &&
    useUserStore().getConfigListBefore();
});
// 点击登录按钮
const onLogin = () => {
  useUserStore().openLogin(true);
};

const getBg = computed(() => {
  return route.meta.backgroundColor ? route.meta.backgroundColor : "#ffffff";
});
const onScroll = ({ scrollTop }: any) => {
  windowScroll.SET_SCROLL_TOP(scrollTop);
  if (route.path === "/home") {
    opacityBg.value = scrollTop < 200;
  }
};
getToken() && useUserStore().getNotReadNum();

function watchUpdate() {
  window.addEventListener("onmessageUpdate", (res: any) => {
    console.log("🚀 ~ file: App.vue:20 ~ window.addEventListener ~ res:", res);
    updateInfo.value = Object.assign({}, res.detail, { type: true });
  });
}
</script>

<style lang="scss">
@import "@/style/mixin.scss";

#app {
  white-space: pre-wrap;

  .es-body {
    height: 100vh;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  .es-pageContent {
    background-color: #ffffff;
    min-height: 50vh;
  }
}

.pageNav {
  @include fixed(2000, -56px, 0, 0, 0);
}

.pageNav-show {
  @include fixed(2000, 0, 0, 0, 0);
}

.es-pageNav--opacity {
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
